import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'index',
    component: () => import('../views/Index.vue'),
    redirect:'home',
    children: [
      {
        path: 'home',
        component: () => import('../pages/Home.vue'),
      },
      {
        path: 'survey',
        component: () => import('../pages/Survey.vue'),
      },
      {
        path: 'news',
        component: () => import('../pages/News.vue'),
      },
      {
        path: 'build',
        component: () => import('../pages/Build.vue'),
      },
      {
        path: 'business',
        component: () => import('../pages/Business.vue'),
      },
      {
        path: 'policy',
        component: () => import('../pages/Policy.vue'),
      },
      {
        path: 'culture',
        component: () => import('../pages/Culture.vue'),
      },
      {
        path: 'information',
        component: () => import('../pages/Information.vue'),
      },

    ]
  },{
    path: '/detail',
    name: 'detail',
    component: () => import('../pages/Detail.vue'), 
    
  },{
    path: '/eacharts',
    name: 'eacharts',
    component: () => import('../pages/eacharts.vue'), 
    
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
